<template>
  <getecma-single-content-layout v-loading="fullscreenLoading" content-class="scroll pe--xxl">
    <template v-if="customer" #content>
      <getecma-breadcrumb :items="itemsBreadcrumb" class="mb--md mt--xl" />
      <div v-if="customer.id">
        <getecma-header size="lg">Editar Cliente</getecma-header>
        <getecma-divider class="my--md" />
        <getecma-customer-edit-information @save="save" />
      </div>
      <div v-if="!customer_id">
        <getecma-header size="lg">Adicionar Cliente</getecma-header>
        <getecma-divider class="my--md" />
        <getecma-customer-create-information @create="save" />
      </div>
    </template>
  </getecma-single-content-layout>
</template>

<script>
import { toastError, toastSuccess } from '@/services/toastService';
import { goToOpenCustomer, goToCustomerForbiddenPage } from '@/modules/customer/customer.routes'; // eslint-disable-line
import { getters } from '@/modules/user/user.store';
import { getCustomerById, updateCustomerInformation, createNewCustomer } from '@/modules/customer/customer.service';
import { USERS_ROLES } from '@/modules/user/user.constants';
import { CUSTOMERS_URL } from '@/modules/customer/customer.constants';

import GetecmaSingleContentLayout from '@/layouts/SingleContentLayout.vue';
import GetecmaCustomerEditInformation from '@/modules/customer/components/CustomerEditComponent.vue';
import GetecmaCustomerCreateInformation from '@/modules/customer/components/CustomerCreateComponent.vue';
import GetecmaBreadcrumb from '@/components/breadcrumb/Breadcrumb.vue';
import GetecmaDivider from '@/components/menu/components/Divider.vue';

export default {
  name: 'GetecmaCustomerEditPage',
  components: {
    GetecmaSingleContentLayout,
    GetecmaCustomerEditInformation,
    GetecmaCustomerCreateInformation,
    GetecmaBreadcrumb,
    GetecmaDivider,
  },
  provide() {
    const customerEditVm = {};
    Object.defineProperty(customerEditVm, 'customer', {
      get: () => this.customer,
    });
    return { customerEditVm };
  },
  data() {
    return {
      itemsBreadcrumb: [
        { name: 'Dashboard', path: '/' },
        { name: 'Clientes', path: CUSTOMERS_URL.path },
        { name: 'Adicionar', path: this.$route.path },
      ],
      currentUser: getters.getUser(),
      customer: null,
      createdCustomer: null,
      fullscreenLoading: false,
    };
  },
  computed: {
    customer_id() {
      return this.$route.params?.id;
    },
  },
  mounted() {
    if (this.customer_id) {
      this.itemsBreadcrumb[2].name = 'Editar';
      if (this.currentUser.permission < USERS_ROLES.ADMIN) goToCustomerForbiddenPage(this.$router, false);
      this.onFetch();
    } else {
      if (this.currentUser.permission < USERS_ROLES.ADMIN) goToCustomerForbiddenPage(this.$router, true);
      this.onFetch();
    }
  },
  methods: {
    onFetch() {
      if (this.customer_id) {
        getCustomerById(this.customer_id)
          .then(data => {
            this.customer = data;
          })
          .catch(() => toastError('Erro ao obter cliente por ID'));
      } else {
        this.customer = {
          name: '',
        };
      }
    },
    save() {
      if (this.customer_id) {
        this.fullscreenLoading = true;
        updateCustomerInformation(this.customer)
          .then(data => {
            this.fullscreenLoading = false;
            toastSuccess('Cliente salvo!');
            goToOpenCustomer(this.$router, data);
          })
          .catch(() => {
            toastError('Erro ao atualizar o perfil do cliente');
            this.fullscreenLoading = false;
          });
      } else {
        this.onCreateNewCustomer(this.customer);
      }
    },
    onCreateNewCustomer(customer) {
      createNewCustomer(customer)
        .then(data => {
          goToOpenCustomer(this.$router, data);
        })
        .catch(() => {
          toastError('Erro ao salvar o cliente');
        });
    },
  },
};
</script>
